<template>
  <div>
    <v-alert outlined type="info" class="text-center"
      >Por favor, ingrese su <b>DNI</b> o <b>CUIT</b> (sin guiones).</v-alert
    >
    <v-text-field
      prepend-inner-icon="mdi-card-account-details"
      v-model="documento"
      autofocus
      required
      solo
      outlined
      :error="$v.documento.$error"
      @input="$v.documento.$touch()"
      @blur="$v.documento.$touch()"
      @keypress="verificarNumeroDocumento"
      :disabled="loading"
      @keydown.enter="verificarDocumento()"
    ></v-text-field>
    <vue-recaptcha
      sitekey="6LcntjoaAAAAACfPmWJq7CMOzC67yRuXH_J13-fb"
      class="mb-4 mt-n4"
      loadRecaptchaScript
      @verify="onVerify"
      v-if="false"
      ref="recaptcha"
    ></vue-recaptcha>
    <v-alert type="error" dense outlined v-if="errorCodigo">{{
      errorCodigo
    }}</v-alert>
    <v-btn
      block
      color="primary"
      @click="verificarDocumento()"
      :loading="loading"
    >
      Siguiente
    </v-btn>
    <v-btn text block class="mt-4" @click="$router.push({ name: 'Ingresar' })"
      >Ya tengo una cuenta</v-btn
    >
  </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import { required } from "vuelidate/lib/validators";
const validarDocumento = (value) => value.length == 8 || value.length == 11;
export default {
  name: "Registro_Documento",
  components: {
    VueRecaptcha,
  },
  data: () => ({
    loading: false,
    documento: "",
    recaptcha: true,
    errorCodigo: false,
    form: {},
    documento_tipo: 'DNI'
  }),
  validations: {
    documento: { required, validarDocumento },
  },
  methods: {
    async consultarDocumento() {
      this.loading = true;
      try {
        let datos = await this.axios.post("/registro/consultarDatos", {
          documento: this.documento,
          recaptcha: this.recaptcha,
        });
        this.loading = false;
        return datos.data;
      } catch (error) {
        this.loading = false;
        return { error: true, codigo: error.response.data };
      }
    },
    async verificarDocumento() {
      this.$v.$touch();
      if (!this.$v.documento.$invalid && this.recaptcha) {
        let datosAFIP = await this.consultarDocumento();
        if (datosAFIP && !datosAFIP.error) {
          let nombre =
            (datosAFIP.apellido ? datosAFIP.apellido + " " : "") +
            (datosAFIP.nombre ? datosAFIP.nombre : "") +
            (datosAFIP.razonSocial ? datosAFIP.razonSocial : "");

          this.form.nombre = this.capitalizar(nombre);

          if (datosAFIP.domicilio[0]) {
            let domicilio = datosAFIP.domicilio[0]

            if (domicilio.descripcionProvincia)
              this.form.provincia = this.capitalizar(
                this.limpiarMay(domicilio.descripcionProvincia)
              );

            if (domicilio.localidad)
              this.form.ciudad = this.capitalizar(
                this.limpiarMay(domicilio.localidad)
              );

            this.form.direccion = this.capitalizar(
              domicilio.calle + " " + domicilio.numero + (domicilio.oficinaDptoLocal
                  ? ` ${domicilio.oficinaDptoLocal}`
                  : "")
            );

            if (domicilio.codigoPostal) this.form.cp = domicilio.codigoPostal;

            if (domicilio.tipoDomicilio)
              this.form.tipoDomicilio = this.capitalizar(domicilio.tipoDomicilio);

            this.form.referencias = this.capitalizar(
              (domicilio.tipoDatoAdicional
                ? domicilio.tipoDatoAdicional + " "
                : "") +
                (domicilio.datoAdicional ? domicilio.datoAdicional : "") 
            );

            this.form.esEmpresa =
              datosAFIP.tipoPersona == "JURIDICA" ? true : false;

            if (datosAFIP.tipoClave == "CUIT")
              this.documento = datosAFIP.idPersona;
            if (this.documento.toString().length > 9)
              this.documento_tipo = "CUIT";
            
            if (datosAFIP.fechaNacimiento)
              this.form.fechaNacimiento = new Date(datosAFIP.fechaNacimiento)
                .toISOString()
                .substr(0, 10);
          }
          if (datosAFIP.fechaContratoSocial)
            this.form.fechaNacimiento = new Date(datosAFIP.fechaContratoSocial)
              .toISOString()
              .substr(0, 10);

            this.form.documento = this.documento
            this.form.documento_tipo = this.documento_tipo
          
          this.$emit("finalizado", { form: this.form, documento: {
            numero: this.documento, tipo: this.documento_tipo
          }});
        } else {
          this.errorCodigo = this.errorCodigoAFIP(datosAFIP.codigo);
        }
      }
    },
    errorCodigoAFIP(codigo) {
      this.$refs.recaptcha.reset();
      this.recaptcha = false;
      switch (codigo) {
        case "errorCaptcha":
          return "Por favor, reintente la verificacion.";
        case "documentoIncorrecto":
          return "El Numero de Documento es incorrecto.";
        case "noEstaPadron":
          return "No pudimos consultar sus datos. Verifique que el Numero de Documento ingresado sea correcto.";
        default:
          return "No se pudo realizar la comprobacion. Por favor, reintente.";
      }
    },
    verificarNumeroDocumento($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    onVerify(response) {
      this.recaptcha = response;
    },
    limpiarMay(string) {
      return string.toUpperCase().replace(/[^\w\s]/gi, "");
    },
    capitalizar(string) {
      return string
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    },
  },
};
</script>
