<template>
  <div>
    <v-text-field
      label="Apellido y Nombre"
      prepend-icon="mdi-account"
      v-model="form.nombre"
      required
      :error="$v.form.nombre.$error"
      @input="$v.form.nombre.$touch()"
      @blur="$v.form.nombre.$touch()"
    ></v-text-field>
    <v-menu
      ref="menu"
      v-model="menuFechaNac"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="fechaNacimiento"
          :label="
            'Fecha de ' + (form.esEmpresa ? 'Conformacion' : 'Nacimiento')
          "
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        ref="picker"
        v-model="form.fechaNacimiento"
        :max="new Date().toISOString().substr(0, 10)"
        min="1900-01-01"
        @change="guardarFechaNac"
      ></v-date-picker>
    </v-menu>
    <v-autocomplete
      label="Provincia"
      prepend-icon="mdi-city"
      v-model="form.provincia"
      required
      :error="$v.form.provincia.$error"
      @input="$v.form.provincia.$touch()"
      @blur="$v.form.provincia.$touch()"
      item-text="nombre"
      item-value="_id"
      :items="provincias"
      :loading="loadingProv"
      @change="cargarProvincias()"
    ></v-autocomplete>
    <v-autocomplete
      label="Ciudad"
      prepend-icon="mdi-home-city"
      v-model="form.ciudad"
      required
      item-text="nombre"
      item-value="_id"
      no-data-text="Debe seleccionar una Provincia"
      :error="$v.form.ciudad.$error"
      @input="$v.form.ciudad.$touch()"
      @blur="$v.form.ciudad.$touch()"
      :items="ciudades"
      :loading="loadingCiu"
    ></v-autocomplete>
    <v-text-field
      label="Codigo Postal"
      prepend-icon="mdi-mailbox-up"
      v-model="form.cp"
      required
      :error="$v.form.cp.$error"
      @input="$v.form.cp.$touch()"
      @blur="$v.form.cp.$touch()"
    ></v-text-field>
    <v-text-field
      label="Direccion"
      prepend-icon="mdi-home"
      v-model="form.direccion"
      required
      :error="$v.form.direccion.$error"
      @input="$v.form.direccion.$touch()"
      @blur="$v.form.direccion.$touch()"
    ></v-text-field>
    <v-text-field
      label="Referencias"
      prepend-icon="mdi-account-question"
      hint="Datos para ubicarnos mejor: Interseccion, Barrio, Piso, Dpto, Local, etc."
      v-model="form.referencias"
    ></v-text-field>
    <v-btn
      block
      class="mt-2"
      color="primary"
      @click="verificarDireccion()"
      :loading="loading"
    >
      Confirmar datos
    </v-btn>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  name: "Registro_Direccion",
  props: {
    datosAFIP: { type: Object, default: {} },
  },
  data: () => ({
    form: {
      nombre: "",
      esEmpresa: false,
      fechaNacimiento: null,
      provincia: null,
      ciudad: null,
      cp: "",
      direccion: "",
      referencias: "",
    },
    menuFechaNac: false,
    provincias: [],
    loadingProv: false,
    ciudades: [],
    loadingCiu: false,
    loading: false,
  }),
  validations: {
    form: {
      nombre: { required },
      provincia: { required },
      ciudad: { required },
      cp: { required },
      direccion: { required },
    },
  },
  created() {
    this.cargarProvincias();
  },
  computed: {
    fechaNacimiento() {
      return this.form.fechaNacimiento
        ? this.$moment(this.form.fechaNacimiento).format("DD/MM/YYYY")
        : "";
    },
  },
  methods: {
    verificarDireccion() {
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        this.$emit('finalizado', this.form)
      }
    },
    async cargarProvincias() {
      let provincia = this.form.provincia;
      if (provincia) {
        this.loadingCiu = true;
      } else {
        this.loadingProv = true;
      }
      try {
        let consulta = await this.axios.post("/registro/buscarCiudad", {
          provincia,
        });
        if (consulta.data.provincias)
          this.provincias = consulta.data.provincias;
        if (consulta.data.ciudades) {
          this.ciudades = consulta.data.ciudades;
          if (this.form.ciudad) {
            let i = this.ciudades.findIndex(
              (val) => this.form.ciudad == val.nombre
            );
            if (i > -1) this.form.ciudad = this.ciudades[i]._id;
          }
        }
      } catch (error) {
        console.log(error);
      }
      if (provincia) {
        this.loadingCiu = false;
      } else {
        this.loadingProv = false;
      }
    },
    guardarFechaNac(date) {
      this.$refs.menu.save(date);
    },
  },
  watch: {
    menuFechaNac(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    datosAFIP() {
      if (this.datosAFIP) {
        this.form = this.datosAFIP;
        let i = this.provincias.findIndex(
          (val) => this.datosAFIP.provincia == val.nombre
        );
        if (i > -1) this.form.provincia = this.provincias[i]._id;
        this.cargarProvincias();
      }
    },
  },
};
</script>
