<template>
  <div>
    <p>
      Ya casi estamos <strong>{{ datos.nombre }}</strong>
    </p>
    <v-list :disabled="esperandoCodigo">
      <v-subheader class="text-h6 mt-n4"
        >Verificar mi cuenta mediante</v-subheader
      >
      <v-list-item-group v-model="metodoVerificacion" color="primary">
        <v-list-item
          v-for="(item, i) in metodoVerificarRegistro"
          :key="i"
          :value="item.value"
          @click="$v.form.$reset()"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-text-field
      prepend-inner-icon="mdi-cellphone"
      v-show="metodoVerificacion == 'cellphone'"
      hint="Enviaremos un SMS a este Numero de Telefono"
      persistent-hint
      v-model="form.tel"
      solo
      outlined
      autofocus
      name="tel"
      type="tel"
      required
      :disabled="esperandoCodigo"
      :error="$v.form.tel.$error"
      @input="$v.form.tel.$touch()"
      @blur="$v.form.tel.$touch()"
      @keydown.enter="paso3()"
    ></v-text-field>
    <v-text-field
      prepend-inner-icon="mdi-email"
      v-show="metodoVerificacion == 'email'"
      hint="Enviaremos un Correo Electronico a esta Direccion. Por favor, verifique su carpeta de SPAM por si el codigo tarda en llegar."
      persistent-hint
      v-model="form.email"
      solo
      outlined
      autofocus
      name="email"
      type="email"
      :disabled="esperandoCodigo"
      :error="$v.form.email.$error"
      @input="$v.form.email.$touch()"
      @blur="$v.form.email.$touch()"
      @keydown.enter="paso3()"
    ></v-text-field>
    <v-text-field
      v-show="esperandoCodigo"
      prepend-inner-icon="mdi-pound"
      hint="Ingresa el Codigo de seis digitos que vas a recibir a la brevedad"
      persistent-hint
      v-model="form.code"
      solo
      outlined
      autofocus
      name="code"
      type="number"
      ref="code"
      :disabled="!esperandoCodigo"
      :error="$v.form.code.$error"
      @input="$v.form.code.$touch()"
      @blur="$v.form.code.$touch()"
      @keydown.enter="paso3()"
    ></v-text-field>
    <v-alert type="error" dense outlined v-if="errorCodigo">{{
      errorCodigo
    }}</v-alert>
    <v-btn
      block
      class="mt-2"
      color="primary"
      :loading="loadingVerificarCuenta"
      @click="paso3()"
    >
      {{
        !esperandoCodigo ? "Enviar codigo de verificacion" : "Verificar cuenta"
      }}
    </v-btn>
  </div>
</template>
<script>
import { required, minLength, maxLength } from "vuelidate/lib/validators";
const esTelefono = (value) => /^\+?[0-9]+$/.test(value);
const longTelefono = (value) => value.length == 10;
export default {
  name: "Registro_Contacto",
  props: {
      documento: {
          type: Object,
          required: true
      },
      datos: {
          type: Object,
          required: true
      }
  },
  data: () => ({
    metodoVerificacion: null,
    form: {
      tel: "",
      email: "",
      code: "",
    },
    esperandoCodigo: false,
    errorCodigo: null,
    loadingVerificarCuenta: false,
    metodoVerificarRegistro: [
      {
        text: "Mensaje de texto (SMS)",
        value: "cellphone",
        icon: "mdi-cellphone",
      },
      { text: "Correo electrónico", value: "email", icon: "mdi-email" },
    ],
  }),
  validations: {
    form: {
      tel: { required, esTelefono, longTelefono },
      email: { required },
      code: { required, minLength: minLength(6), maxLength: maxLength(6) },
    },
  },
  methods: {
    async verificarCuenta() {
      this.errorCodigo = false;
      try {
        let chan = "sms";
        if (this.metodoVerificacion == "cellphone") {
          this.form.email = "";
        } else {
          this.form.tel = "";
          chan = "email";
        }
        let params = {
          tel: this.form.tel,
          email: this.form.email,
          code: this.form.code,
          chan,
          form: this.form.code
            ? {
                step1: this.documento,
                step2: this.datos,
              }
            : null,
        };
        const verificar = await this.axios.post(
          "/registro/verificarCuenta",
          params
        );
        return verificar.data;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    async paso3() {
      this.loadingVerificarCuenta = true;
      try {
        if (this.metodoVerificacion == "cellphone") this.$v.form.tel.$touch();
        if (this.metodoVerificacion == "email") this.$v.form.email.$touch();
        if (this.esperandoCodigo) this.$v.form.code.$touch();
        if (
          this.metodoVerificacion &&
          (!this.$v.form.tel.$invalid || !this.$v.form.email.$invalid)
        ) {
          if (
            !this.esperandoCodigo ||
            (this.esperandoCodigo && !this.$v.form.code.$invalid)
          ) {
            let verificar = await this.verificarCuenta();
            switch (verificar.status) {
              case "pending":
                if (!this.esperandoCodigo) {
                  this.esperandoCodigo = true;
                } else {
                  this.errorCodigo = "El Codigo ingresado es incorrecto.";
                  this.form.code = "";
                  this.$v.form.code.$reset();
                  this.$refs.code.focus();
                }
                break;
              case "existe":
                this.errorCodigo = `Este ${
                  this.metodoVerificacion == "cellphone"
                    ? "Numero de Telefono"
                    : "Correo Electronico"
                } ya esta en uso.`;
                break;
              case "aprobado":
                this.$router.push({
                  name: "Ingresar",
                  params: { token: verificar.token, usuario: verificar.usuario, volver: this.$route.params.volver },
                });
                break;
              default:
                if (this.esperandoCodigo) {
                  this.errorCodigo =
                    "No se pudo realizar la verificacion. Por favor, reintente.";
                } else {
                  this.errorCodigo = `El ${
                    this.metodoVerificacion == "cellphone"
                      ? "Numero de Telefono"
                      : "Correo Electronico"
                  } ingresado es incorrecto.`;
                }
                break;
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
      this.loadingVerificarCuenta = false;
    },
  },
};
</script>
