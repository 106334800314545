<template>
  <v-container fluid fill-height>
    <v-row wrap align-center>
      <v-col>
        <v-stepper v-model="paso">
          <v-stepper-header>
            <v-stepper-step :complete="paso > 1" step="1">
              Datos personales
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="paso > 2" step="2">
              Domicilio
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3">
              Contacto
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <Registro_Documento @finalizado="paso1"></Registro_Documento>
            </v-stepper-content>
          </v-stepper-items>
          <v-stepper-items>
            <v-stepper-content step="2">
              <Registro_Direccion @finalizado="paso2" :datosAFIP="datosAFIP"></Registro_Direccion>
            </v-stepper-content>
          </v-stepper-items>
          <v-stepper-items>
            <v-stepper-content step="3">
              <Registro_Contacto :documento="documento" :datos="datos"></Registro_Contacto>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Registro_Documento from "../components/Registro/Documento"
import Registro_Direccion from "../components/Registro/Direccion"
import Registro_Contacto from "../components/Registro/Contacto"
export default {
  name: "Registro",
  metaInfo: {
      title: 'Registrarse',
    },
  components: {Registro_Documento, Registro_Direccion, Registro_Contacto},
  data: () => ({
    paso: 1,
    datosAFIP: {},
    documento: {},
    datos: {}
  }),
  methods: {
    paso1(form) {
      this.datosAFIP = form.form
      this.documento = form.documento
      this.paso = 2
    },
    paso2(datos) {
      this.datos = datos
      this.paso = 3
    }
  },
};
</script>
